import { useState } from 'react';
import getCount from './getCount';


function App() {
  const [count, setCount] = useState(0);
  const count2 = getCount;
  function handleClick() {
    setCount(count + 1);
  }

    // let count = onRequestGet("POST");
  // function handleClick() {
  //   onRequestGet('POST');
  // }

  return (
    <div className="hero min-h-screen bg-base-200">
      <div className="hero-content text-center">
        <div className="max-w-lg">
          <h1 className="text-6xl font-bold">Universal Reactive Counter</h1>
          <p className="py-6 text-xl">Showerthought 🤔: Every single reactive framework uses a simple counter as their example. How many counters have all the counters across all the frameworks been counted?</p>
          <button onClick={handleClick} className="btn btn-primary">Add to the count</button>
          <div className="mt-12">
            <p className="text-8xl">{count}, {count2.value}</p>
          </div>
        </div>
      </div>
    </div>
  );
}



export default App;