export default {
  async fetch(request, env) {
    let id = env.COUNTER.idFromName(new URL(request.url).pathname);

    let stub = env.COUNTER.get(id);

    let response = await stub.fetch(request);

    return response;
  },
};